<template>
  <div id="talkUrl"></div>
</template>

<script>
import PCMPlayer from "pcm-player";

function Recorder(stream, ws) {
  var sampleBits = 16; //输出采样数位 8, 16
  var sampleRate = 8000; //输出采样率
  var context = new AudioContext();
  var audioInput = context.createMediaStreamSource(stream);
  var audioTracks = stream.getAudioTracks();
  var recorder = context.createScriptProcessor(4096, 1, 1);
  var audioData = {
    size: 0, //录音文件长度
    buffer: [], //录音缓存
    inputSampleRate: 48000, //输入采样率
    inputSampleBits: 16, //输入采样数位 8, 16
    outputSampleRate: sampleRate, //输出采样数位
    oututSampleBits: sampleBits, //输出采样率
    clear: function () {
      this.buffer = [];
      this.size = 0;
    },
    input: function (data) {
      this.buffer.push(new Float32Array(data));
      this.size += data.length;
    },
    compress: function () {
      //合并压缩
      //合并
      var data = new Float32Array(this.size);
      var offset = 0;
      for (var i = 0; i < this.buffer.length; i++) {
        data.set(this.buffer[i], offset);
        offset += this.buffer[i].length;
      }
      //压缩
      var compression = parseInt(this.inputSampleRate / this.outputSampleRate);
      var length = data.length / compression;
      var result = new Float32Array(length);
      var index = 0,
        j = 0;
      while (index < length) {
        result[index] = data[j];
        j += compression;
        index++;
      }
      return result;
    },
    encodePCM: function () {
      //这里不对采集到的数据进行其他格式处理，如有需要均交给服务器端处理。
      var sampleRate = Math.min(this.inputSampleRate, this.outputSampleRate);
      var sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
      var bytes = this.compress();
      var dataLength = bytes.length * (sampleBits / 8);
      var buffer = new ArrayBuffer(dataLength);
      var data = new DataView(buffer);
      var offset = 0;
      for (var i = 0; i < bytes.length; i++, offset += 2) {
        var s = Math.max(-1, Math.min(1, bytes[i]));
        data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
      }
      return new Blob([data]);
    },
  };

  var sendData = function () {
    //对以获取的数据进行处理(分包)
    var reader = new FileReader();
    reader.onload = (e) => {
      var outbuffer = e.target.result;
      var arr = new Int8Array(outbuffer);
      if (arr.length > 0) {
        var tmparr = new Int8Array(1024);
        var j = 0;
        for (var i = 0; i < arr.byteLength; i++) {
          tmparr[j++] = arr[i];
          if ((i + 1) % 1024 == 0) {
            ws.send(tmparr);
            if (arr.byteLength - i - 1 >= 1024) {
              tmparr = new Int8Array(1024);
            } else {
              tmparr = new Int8Array(arr.byteLength - i - 1);
            }
            j = 0;
          }
          if (i + 1 == arr.byteLength && (i + 1) % 1024 != 0) {
            ws.send(tmparr);
          }
        }
      }
    };
    reader.readAsArrayBuffer(audioData.encodePCM());
    audioData.clear(); //每次发送完成则清理掉旧数据
  };

  this.start = function () {
    audioInput.connect(recorder);
    recorder.connect(context.destination);
  };

  this.stop = function () {
    recorder.disconnect();
    audioTracks.forEach(function (track) {
      track.stop();
    });
  };

  this.getBlob = function () {
    return audioData.encodePCM();
  };

  this.clear = function () {
    audioData.clear();
  };

  recorder.onaudioprocess = function (e) {
    var inputBuffer = e.inputBuffer.getChannelData(0);
    audioData.input(inputBuffer);
    sendData();
  };
}
export default {
  data() {
    return {
      talkUrl: "",
      ws: null,
      record: null,
      player: null,
    };
  },
  watch: {
    talkUrl(value) {
      value && this.intercomBegin();
    },
  },
  methods: {
    initSocket(mediaStream) {
      this.ws = new WebSocket(this.talkUrl);
      this.ws.binaryType = "arraybuffer";
      this.player = new PCMPlayer({
        encoding: "16bitInt",
        channels: 1,
        sampleRate: 8000,
        flushTime: 2000,
      });
      this.ws.onopen = () => {
        if (this.ws.readyState == 1) {
          //ws进入连接状态，则每隔500毫秒发送一包数据
          this.record = new Recorder(mediaStream, this.ws);
          this.record.start();
          this.$emit("openSuccess");
        }
      };
    },
    intercomBegin() {
      navigator.getUserMedia =
        navigator.getUserMedia || navigator.webkitGetUserMedia;

      if (!navigator.getUserMedia) {
        this.$message.error("浏览器不支持音频输入");
      } else {
        navigator.getUserMedia(
          {
            audio: true,
          },
          (mediaStream) => {
            this.initSocket(mediaStream);
          },
          (error) => {
            switch (error.message || error.name) {
              case "PERMISSION_DENIED":
              case "PermissionDeniedError":
                this.$message.error("用户拒绝提供信息");
                break;
              case "NOT_SUPPORTED_ERROR":
              case "NotSupportedError":
                this.$message.error("浏览器不支持硬件设备");
                break;
              case "MANDATORY_UNSATISFIED_ERROR":
              case "MandatoryUnsatisfiedError":
                this.$message.error("无法发现指定的硬件设备");
                break;
              default:
                this.$message.error(
                  "无法打开麦克风,异常信息:" + (error.code || error.name)
                );
                break;
            }
          }
        );
      }
    },
  },
  beforeDestroy() {
    this.ws && this.ws.close();
    this.record && this.record.stop();
    this.player && this.player.destroy();
  },
};
</script>
